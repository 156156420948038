// ============================================================================
// HomeService
// -----------
// Home module related services
// ============================================================================

// -------
// Imports
// -------
import API from '@/apis/HomeApi'

// -------
// Exports
// -------
export default {
	listApplications: function (accountingFirmId, vendorId = null) {
		let result = Promise.resolve()

		if (vendorId) {
			result = API.listVendorApplications(accountingFirmId, vendorId, {
				is_home: true,
				with: 'oauth_provider'
			})
		} else {
			result = API.listAccountingFirmApplications(accountingFirmId, {
				is_home: true,
				with: 'oauth_provider'
			})
		}

		return result
			.then(response => {
				return response?.data?.data
			})
			.then(data => data?.sort(function (a, b) {
				return a.id - b.id;
			})
		)
	}
}
