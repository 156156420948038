<template>
	<v-layout column fill-height px-0 mx-0>
		<v-flex scroll-y>
			<v-layout row wrap align-center justify-center mt-3>
				<v-flex v-if="logo && $vuetify.breakpoint.mdAndUp" shrink>
					<v-img :src="logo" contain height="15vh" width="20vw" max-height="15vh" max-width="20vw" />
				</v-flex>
				<v-flex v-if="welcomeMessage" xs12 my-3 headline text-xs-center>
					<span>{{ welcomeMessage }}</span>
				</v-flex>
			</v-layout>
			<v-layout grow>
				<v-flex column :scroll-y="$vuetify.breakpoint.mdAndUp">
					<v-layout v-if="moduleCards && moduleCards.length > 0" shrink column px-5 my-1>
						<v-flex
							v-t="'home.modules_title'"
							shrink
							title
							:text-xs-start="$vuetify.breakpoint.mdAndUp"
							:text-xs-center="$vuetify.breakpoint.smAndDown"
							:justify-start="$vuetify.breakpoint.mdAndUp"
							:justify-center="$vuetify.breakpoint.smAndDown"
						/>
						<v-flex grow my-2>
							<CardsList :pictures="false" :content="moduleCards" />
						</v-flex>
					</v-layout>
					<v-layout v-if="applicationCards && applicationCards.length > 0" shrink column px-5 my-1>
						<v-flex
							v-t="'home.applications_title'"
							shrink
							title
							:text-xs-start="$vuetify.breakpoint.mdAndUp"
							:text-xs-center="$vuetify.breakpoint.smAndDown"
							:justify-start="$vuetify.breakpoint.mdAndUp"
							:justify-center="$vuetify.breakpoint.smAndDown"
						/>
						<v-flex
							grow
							my-2
							:justify-start="$vuetify.breakpoint.mdAndUp"
							:justify-center="$vuetify.breakpoint.smAndDown"
							:align-start="$vuetify.breakpoint.mdAndUp"
							:align-center="$vuetify.breakpoint.smAndDown"
						>
							<CardsList :pictures="true" :content="applicationCards" />
						</v-flex>
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import HomeModuleGuard from '@/mixins/ModulesGuards/Home/HomeModuleGuard'

export default {
	name: 'HomeScreen',
	components: {
		CardsList: () => ({
			component: import('@/components/Commons/CardsList')
		})
	},
	mixins: [HomeModuleGuard],
	data: function () {
		return {
			applications: []
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			logo: state => state.whitelabel.selected.logo,
			modules: state => state.modules.home
		}),
		accountingFirm: function () {
			return this.selectedAccountingFirm
		},
		applicationCards: function () {
			return this.applications.map(application => {
				return {
					img: application.logo,
					routeName: application?.oauth_provider?.url_sign_on
						? {
								name: 'go-to',
								params: {
									providerId: application.oauth_provider.id
								}
						  }
						: application.uri,
					subtitle: application.description,
					title: application.title
				}
			})
		},
		moduleCards: function () {
			let result = []
			if (Array.isArray(this.modules)) {
				result = this.modules.map(mod => {
					return {
						disabled: !mod.is_active,
						id: mod.id,
						img: mod.icon,
						routeName: mod.name,
						subtitle: mod.description,
						title: mod.title,
						visible: true
					}
				})
			}
			return result
		},
		vendor: function () {
			return this.selectedVendor
		},
		welcomeMessage: function () {
			let result = null
			if (this.accountingFirm?.welcome_message) {
				result = this.accountingFirm.welcome_message
			} else {
				result = this.$t('home.title', { entityName: this.accountingFirm?.name })
			}
			return result
		}
	},
	watch: {
		accountingFirmId: {
			handler: function (accountingFirmId) {
				if (this.isAccountant && accountingFirmId) {
					this.loadContent()
				} else {
					this.applications.splice(0, this.applications.length)
				}
			}
		},
		vendorId: {
			handler: function (vendorId) {
				if (!this.isAccountant && vendorId) {
					this.loadContent()
				}
			}
		}
	},
	mounted: function () {
		this.loadContent()
		window.addEventListener('dragover', e => {
			e.preventDefault()
		})
		window.addEventListener('drop', e => {
			e.preventDefault()
		})
	},
	methods: {
		loadApplications: function () {
			let vendorId = this.vendorId
			if (this.isAccountant) {
				vendorId = null
			}

			this.applications.splice(0, this.applications.length)

			return this.service
				.listApplications(this.accountingFirmId, vendorId)
				.then(applications => {
					this.applications = applications
				})
				.catch(error => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
					throw error
				})
		},
		loadContent: function () {
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)

			this.loadApplications().finally(() => {
				this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
			})
		}
	}
}
</script>
