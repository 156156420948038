import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/applications
 *
 * @param {Number} accountingFirmId
 * @param {Object} params
 *
 * @return {Promise<object>}
 */
const listAccountingFirmApplications = (accountingFirmId, params = {}) => {
	const url = `accounting-firms/${accountingFirmId}/applications`
	return axios.get(url, { params })
}

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/vendors/{vendorId}/applications
 *
 * @param {Number} accountingFirmId
 * @param {Number} vendorId
 * @param {Object} params
 *
 * @return {Promise<object>}
 */
const listVendorApplications = (accountingFirmId, vendorId, params) => {
	const url = `accounting-firms/${accountingFirmId}/vendors/${vendorId}/applications`
	return axios.get(url, { params })
}

export default {
	listAccountingFirmApplications,
	listVendorApplications
}
